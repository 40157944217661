import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ILeaveRequestAddedEvent,
  ILeaveRequestApprovedEvent,
  ILeaveRequestCancelledEvent,
  ILeaveRequestCreatedEvent,
  ILeaveRequestDeletedEvent,
  ILeaveRequestDeniedEvent,
  ILeaveRequestExpiredEvent
} from '@vacationtracker/shared/types/leave-request'
import { ISubscriptionEventMessage } from '@vacationtracker/shared/types/notification'
import { RootState } from './configure'

export type LeaveRequestActionEvent = ILeaveRequestCreatedEvent
| ILeaveRequestExpiredEvent
| ILeaveRequestDeniedEvent
| ILeaveRequestDeletedEvent
| ILeaveRequestCancelledEvent
| ILeaveRequestAddedEvent
| ILeaveRequestApprovedEvent
| ISubscriptionEventMessage
| null

interface IInitialState {
  leaveRequestActionEvent: LeaveRequestActionEvent
}

const initialState: IInitialState = {
  leaveRequestActionEvent: null,
}

export const leaveRequestActionEventSlice = createSlice({
  name: 'leaveRequestActionEvent',
  initialState,
  reducers: {
    setLeaveRequestActionEvent: (state, action: PayloadAction<LeaveRequestActionEvent>) => {
      state.leaveRequestActionEvent = action.payload
    },
  },
})

export const { setLeaveRequestActionEvent } = leaveRequestActionEventSlice.actions

export const selectLeaveRequestActionEventSlice = (state: RootState) => state.leaveRequestActionEventSlice

export default leaveRequestActionEventSlice.reducer