import moment from 'moment'
import { IHoliday } from '@vacationtracker/shared/types/holidays'

const holidayDataWrapper = (data) => {
  const holidaysArray: IHoliday[] = []
  data.forEach((single => {
    if (single.multiDayId) {
      if (holidaysArray.filter(holiday => single.multiDayId === holiday.multiDayId).length === 0) {
        const filtered = data
          .filter(holiday => holiday.multiDayId === single.multiDayId)
          .sort((a: IHoliday, b: IHoliday) => moment(a.date).format() < moment(b.date).format() ? -1 : 1)
        holidaysArray.push({ ...single, endDate: filtered[filtered.length - 1].date })
      }
    } else {
      delete single.multiDayId
      holidaysArray.push(single)
    }
  }))

  return holidaysArray.sort((a: IHoliday, b: IHoliday) => a.date < b.date ? -1 : 1)
}
export default holidayDataWrapper
