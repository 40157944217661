import React from 'react'
import { Route, Switch } from 'react-router-dom'

import asyncComponent from '../util/asyncComponent'

interface IApp {
  match: {
    url: string
  }
}

const App = ({ match }: IApp) => {

  return (
    <div className="main-content-wrapper">
      <Switch>
        <Route path={`${match.url}tabs/dashboard`} component={asyncComponent(() => import('./DashboardPage'))} />
        <Route path={`${match.url}tabs/my-profile`} component={asyncComponent(() => import('./MyProfilePage'))} />
        <Route path={`${match.url}tabs/request-leave`} component={asyncComponent(() => import('./RequestLeavePage'))} />
        <Route path={`${match.url}tabs/config`} component={asyncComponent(() => import('./ConfigTabPage'))} />
        <Route path={`${match.url}tabs/calendar`} component={asyncComponent(() => import('./CalendarTabPage'))} />
      </Switch>
    </div>
  )
}

export default App
