import React from 'react'
import CalendarTabComponent from './CalendarTabComponent'
import PageWrapper from '../../containers/App/PageWrapper'
import TopBar from '../../components/topbar'

const CalendarTabPage: React.FC = () => {

  return (
    <>
      <TopBar />
      <div className="main-content main-content-solid">
        <PageWrapper PageComponent={CalendarTabComponent} />
      </div>
    </>
  )
}

export default CalendarTabPage