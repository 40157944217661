import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configure'


interface UserIdState {
  userId: string
}

const initialState: UserIdState = {
  userId: '',
}

export const userIdSlice = createSlice({
  name: 'userId',
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload
    },
  },
  // example how to react to actions dispatched from another slices
  //  we should first import that action, i.e. reset
  // extraReducers: builder => builder.addCase(reset, (state, action) => {
  //   state.userId = state.userId + 'bla bla bla'
  // }),

})

export const { setUserId } = userIdSlice.actions

export const selectUserIdSlice = (state: RootState) => state.userIdSlice

export default userIdSlice.reducer