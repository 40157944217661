import { configureStore, combineReducers } from '@reduxjs/toolkit'
import notificationsSlice from './NotificationsSlice'
import userIdSlice from './UserIdSlice'
import authCompanySlice from './auth-company-slice'
import featureFlagsSlice from './FeatureFlagsSlice'
import themeSlice from './ThemeSlice'
import localeSlice from './LocaleSlice'
import leaveRequestActionEventSlice from './leave-request-action-event-slice'

const rootReducer = combineReducers({
  notificationsSlice,
  userIdSlice,
  authCompanySlice,
  featureFlagsSlice,
  themeSlice,
  localeSlice,
  leaveRequestActionEventSlice,
})

export const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch