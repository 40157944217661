import React from 'react'
import { Layout } from 'antd'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { CopyrightCircleOutlined } from '@ant-design/icons'
import moment from 'moment'

import App from '../../routes/index'
import IntlMessages from '../../util/IntlMessages'

const { Content, Footer } = Layout

const MainApp = () => {
  const match = useRouteMatch()
  const location = useLocation()
  return (
    <Layout className="content-layout" style={{ backgroundColor: 'transparent' }}>
      <Content>
        <App match={match} />
        {location.pathname !== '/tabs/request-leave' && <Footer>
          <IntlMessages id="app.copyright" /> <CopyrightCircleOutlined /> {moment().format('YYYY')}
        </Footer>
        }
      </Content>
    </Layout>
  )
}
export default MainApp

