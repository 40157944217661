import React, { createContext, useReducer } from 'react'

import { reducer, initialSettings } from './reducer'
import { actions } from './actions'

const store = createContext(initialSettings)
const { Provider } = store


const AppProvider = ({ children }) => {

  const [state, dispatch] = useReducer(reducer, initialSettings)

  const value = {
    ...state,
    ...actions(dispatch),
  }

  return <Provider value={value}>{children}</Provider>
}

export { store, AppProvider }