import React from 'react'
import MyProfileTabComponent from './MyProfileTabComponent'
import PageWrapper from '../../containers/App/PageWrapper'

const MyProfileTabPage: React.FC = () => {
  return (
    <>
      <div className="profile main-content">
        <PageWrapper PageComponent={MyProfileTabComponent} />
      </div>
    </>
  )
}

export default MyProfileTabPage