import { IDashboardLeaveRequest, LeaveRequestStatus } from '@vacationtracker/shared/types/leave-request'
import { IFilter } from '@vacationtracker/shared/types/filter'

export interface ILocation {
  id: string
  name: string
}

export interface ITeam {
  id: string
  name: string
}

export interface ITeamWithApprovers extends ITeam {
  approvers: {
    id: string
  }[]
}


export interface ILabel {
  color: string
  id: string
  name: string
}

export interface ILeaveRequest {
  approver: {
    id: string
    name: string
  }
  autoApproved: boolean
  calendarDays: number
  endDate: string
  id: string
  isPartDay: false
  leavePolicy: {
    hideLeaveType: boolean
    leaveType: {
      color: string
      deleted: boolean
      id: string
      name: string
    }
  }
  partDayEndHour: number
  partDayStartHour: number
  reason: string
  startDate: string
  status: LeaveRequestStatus
  totalNextYear: number
  totalThisYear: number
  workingDays: number
  user: {
    id: string
    imageUrl: string
    labels: ILabel[]
    location: ILocation
    name: string
    team: ITeamWithApprovers
  }
  isEdited?: boolean
}

export interface IFilterConfig {
  filter: IFilter
  initialData: IDashboardLeaveRequest[]
  moreInitialData: IDashboardLeaveRequest[]
  pickFirstFive: boolean
  filteredDataSetter: (v) => void
  filteredMoreDataSetter: (v) => void
  initialDataSetter?: (v) => void
  moreInitialDataSetter?: (v) => void
}
export interface IGetUserIdsForApproverTo {
  id: string
}

export interface IGetUserIdsForApprover {
  id: string
  workWeek: number[]
  approverTo: IGetUserIdsForApproverTo[]
}

export interface IGetUserIdsForApproverData {
  data: {
    getUser: IGetUserIdsForApprover
  }
}

export enum ShowMoreTargetEnum {
  openLeaves = 'openLeaves',
  todaysLeaves = 'todaysLeaves',
  upcomingLeaves = 'upcomingLeaves',
  holidays = 'holidays'
}