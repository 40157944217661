import React, { ReactElement } from 'react'
import { ISubscriptionEvent, ISubscriptionEventMessage } from '../types/notification'
import { Button } from 'antd'

// Third kind of "Notification", event success/failure on the dashboard
interface INotificationInfoResponse {
  action: string
  notifications: string[] | object
  options: {
    key: string
    message: string
    title?: string
    titleValues?: { [key: string]: any }
    description?: string | object
    descriptionValues?: { [key: string]: any }
    btn?: ReactElement
    duration: number
    updateNotification: boolean
  }
}

export function generateNotificationInfo(event: ISubscriptionEvent, formatMessage): INotificationInfoResponse {
  const action = event.code || 'open'

  let subscriptionEvent: ISubscriptionEventMessage | null = null
  const message: string = event.message
  try {
    subscriptionEvent = JSON.parse(event.originalEvent)
  } catch (err) {
    // Generic message
  }

  let title
  let titleValues = {}
  let description
  let updateNotification = true
  const descriptionValues: any = {}
  let button: ReactElement | undefined
  const key = subscriptionEvent?.correlationId || ''

  if (action !== 'error') {
    switch (subscriptionEvent?.eventType) {
      case 'LOCATION_CREATED':
        button = (<Button type="primary" size="small" href={`/app/settings/locations/${subscriptionEvent?.locationId}/general`}>
          {formatMessage({ id: 'actionNotifications.location.view' })}
        </Button>)
        title = 'actionNotifications.location.createdTitle'
        titleValues = { locationName: subscriptionEvent.name }
        break

      case 'LOCATION_UPDATED':
        button = (<Button type="primary" size="small" href={`/app/settings/locations/${subscriptionEvent?.locationId}/general`}>
          {formatMessage({ id: 'actionNotifications.location.view' })}
        </Button>)
        title = 'actionNotifications.location.updatedTitle'
        titleValues = { locationName: subscriptionEvent.name }
        break

      case 'LOCATION_DELETED':
        title = 'actionNotifications.location.deletedTitle'
        break

      case 'TEAM_CREATED':
        button = (<Button type="primary" size="small" href={`/app/settings/departments/${subscriptionEvent?.teamId}`}>
          {formatMessage({ id: 'departments.viewDepartment' })}
        </Button>)
        title = 'actionNotifications.team.createdTitle'
        titleValues = { teamName: subscriptionEvent.name }
        break

      case 'TEAM_UPDATED':
        button = (<Button type="primary" size="small" href={`/app/settings/departments/${subscriptionEvent?.teamId}`}>
          {formatMessage({ id: 'departments.viewDepartment' })}
        </Button>)
        title = 'actionNotifications.team.updatedTitle'
        titleValues = { teamName: subscriptionEvent.name }
        break

      case 'TEAM_DELETED':
        title = 'actionNotifications.team.deletedTitle'
        break

      case 'LEAVE_REQUEST_APPROVED':
        title = 'actionNotifications.leave.requestApproved'
        break

      case 'LEAVE_REQUEST_DENIED':
        title = 'actionNotifications.leave.requestDenied'
        break

      case 'NOTIFICATION_CREATED':
        title = 'actionNotifications.notification.updated'
        break

      case 'LEAVE_TYPE_UPDATED':
        title = 'actionNotifications.leaveType.updated'
        titleValues = { leaveTypeName: subscriptionEvent.name }
        break

      case 'LEAVE_TYPE_CREATED':
        title = 'actionNotifications.leaveType.created'
        titleValues = { leaveTypeName: subscriptionEvent.name }
        break

      case 'LEAVE_TYPE_DELETED':
        title = 'actionNotifications.leaveType.deleted'
        break

      case 'LEAVE_POLICY_ENABLED':
        title = 'components.locationLeavePolicy.successTitle'
        break

      case 'LEAVE_REQUEST_CANCELLED':
        title = 'user.cancelLeaveRequestTitle'
        break

      case 'LEAVE_REQUEST_ADDED':
        title = 'addLeave.title'
        break

      case 'LEAVE_REQUEST_UPDATED':
        title = 'editLeave.title'
        break

      case 'LEAVE_REQUEST_CREATED':
        title = 'requestLeave.title'
        break

      case 'LOCATION_YEAR_HOLIDAYS_CHANGED':
        title = 'location.holidays.successTitle'
        break

      case 'LOCATION_HOLIDAYS_AUTOIMPORT_CHANGED':
        title = 'location.holidays.successTitle'
        break

      case 'USER_UPDATED':
        title = 'user.updateSuccessTitle'
        break

      case 'USER_STATUS_CHANGED':
        title = 'user.status.updateSuccessTitle'
        break

      case 'USER_LEAVE_TYPES_UPDATED':
        title = 'users.updateQuota.successTitle'
        break

      case 'LOCATION_USER_MOVED':
        title = 'user.location.moveSuccessTitle'
        titleValues = { name: subscriptionEvent.name }
        break

      case 'TEAM_USER_MOVED':
        title = 'user.team.moveSuccessTitle'
        titleValues = { name: subscriptionEvent.name }
        break

      case 'LEAVE_REQUEST_DELETED':
        title = 'user.deleteLeaveSuccessTitle'
        break

      case 'USER_WORK_WEEK_UPDATED':
        title = 'user.workWeek.updateSuccessTitle'
        break

      case 'SYNC_EXISTING_USERS':
        title = 'users.syncExistingUsers.successTitle'
        break

      case 'BULK_USER_STATUS':
        title = 'user.bulkChangeStatusSuccessTitle'
        break

      case 'NOTIFICATION_DELETED':
        title = 'actionNotifications.notification.deleted'
        break

      case 'COMPANY_SETTINGS_UPDATED':
        title = 'general.updateSuccessTitle'
        break

      case 'BILLING_UPDATED':
        title = 'billing.updatedSuccessfully'
        if (subscriptionEvent.stripeCouponId && action === 'error') {
          description = 'billing.promoCodeErrorDescription'
          descriptionValues.code = subscriptionEvent.stripeCouponId
        }
        else if (window.location.pathname !== '/subscription') {
          updateNotification = false
        }
        break

      case 'PAYMENT_PROCESSOR_UPDATED':
        // this event is triggred together with BILLING_UPDATED and we want to avoid showing a success message before the other event is finished
        // BILLING_UPDATED fails if payment is not processed
        if (window.location.pathname === '/subscription' && action === 'success') {
          updateNotification = false
          break
        }
        title = 'billing.updatedSuccessfully'
        break

      case 'SCHEDULED_REPORT_CREATED':
        button = (<Button type="primary" size="small" href={`/app/reports/scheduled-reports/${subscriptionEvent?.scheduledReportId}/edit`}>
          {formatMessage({ id: 'actionNotifications.scheduledReport.viewScheduledReport' })}
        </Button>)
        title = 'actionNotifications.scheduledReport.createdTitle'
        titleValues = { scheduledReportName: subscriptionEvent.name }
        break

      case 'SCHEDULED_REPORT_UPDATED':
        button = (<Button type="primary" size="small" href={`/app/reports/scheduled-reports/${subscriptionEvent?.scheduledReportId}/edit`}>
          {formatMessage({ id: 'actionNotifications.scheduledReport.viewScheduledReport' })}
        </Button>)
        title = 'actionNotifications.scheduledReport.updatedTitle'
        titleValues = { scheduledReportName: subscriptionEvent.name }
        break

      case 'SCHEDULED_REPORT_DELETED':
        title = 'actionNotifications.scheduledReport.deletedTitle'
        break
    }
  }


  const response: INotificationInfoResponse = {
    action,
    options: {
      key,
      message,
      duration: action === 'success' ? 10 : 0,
      updateNotification,
    },
    notifications: [],
  }

  if (key) {
    response.options.key = key
  }

  if (title) {
    response.options.title = title
    response.options.titleValues = titleValues
  }

  if (description) {
    response.options.description = description
    response.options.descriptionValues = descriptionValues
  }

  if (button) {
    response.options.btn = button
  }

  return response
}