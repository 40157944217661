const asBase64EncodedJson = value => Buffer.from(JSON.stringify(value), 'utf8').toString('base64')

export const createAppSyncAuthorizedWebSocket = (getAppSyncAuthorizationInfo) => {
  return class extends WebSocket {
    // SubscriptionClient takes a fixed websocket url so we append query string parameters every time the websocket
    // is created, in case the authorization information has changed.
    constructor(url, protocols = undefined) {
      super(
        `${url}?header=${asBase64EncodedJson(getAppSyncAuthorizationInfo)}&payload=${asBase64EncodedJson({})}`,
        protocols
      )
    }

    send(originalData) {
      const data = this._tryParseJsonString(originalData)

      // AppSync's subscription event requires extensions
      // and a slightly different message format
      if (data?.payload?.query) {
        return super.send(JSON.stringify({
          ...data,
          payload: {
            data: JSON.stringify({
              query: data.payload.query,
              variables: data.payload.variables,
            }),
            extensions: {
              authorization: getAppSyncAuthorizationInfo,
            },
          },
        }))
      }

      return super.send(originalData)
    }

    // AppSync acknowledges GraphQL subscriptions with "start_ack" messages but SubscriptionClient cannot handle them
    set onmessage(handler) {
      super.onmessage = event => {
        if (event.data) {
          const data = this._tryParseJsonString(event.data)

          if (data && data.type === 'start_ack') {
            return
          }
        }

        return handler(event)
      }
    }

    _tryParseJsonString(jsonString) {
      try {
        return JSON.parse(jsonString)
      } catch (e) {
        return undefined
      }
    }
  }
}