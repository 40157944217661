import React, { useEffect } from 'react'
import { GraphQLClient, ClientContext } from 'graphql-hooks'
import './styles/theme.less'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import * as microsoftTeams from '@microsoft/teams-js'
import { App as AntApp } from 'antd'

import App from './containers/App/index'
import { selectThemeSlice, setTheme } from './store/ThemeSlice'
import { MicrosoftTeams } from './services/auth/microsoft/microsoft'
import { TeamsThemeType } from '@vacationtracker/shared/constants/ThemeSetting'
import { useAppDispatch, useAppSelector } from './store/hooks'

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID!,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID!,
      allowGuestAccess: false,
    },
  },
})

const GraphQlApiUrl = process.env.REACT_APP_GRAPHQL_ENDPOINT || ''

const graphQlClient = new GraphQLClient({
  url: GraphQlApiUrl,
})

const MainApp = () => {
  const msAuth = new MicrosoftTeams()
  const dispatch = useAppDispatch()
  const { theme } = useAppSelector(selectThemeSlice)

  useEffect(() => {
    msAuth.getContext().then(msContext => {
      msContext.app.theme && handleThemeChange(msContext.app.theme)
    })
    microsoftTeams.app.registerOnThemeChangeHandler(handleThemeChange)
  }, [theme])

  const handleThemeChange = (theme) => {
    dispatch(setTheme(theme as TeamsThemeType))
  }
  return (<>
    <ClientContext.Provider value={graphQlClient}>
      <AntApp>
        <BrowserRouter>
          <Switch>
            <Route path="/" render={(routerProps) => <App {...routerProps} />} />
          </Switch>
        </BrowserRouter>
      </AntApp>
    </ClientContext.Provider>
  </>)
}
export default MainApp
