import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configure'

import { TeamsThemeType } from '@vacationtracker/shared/constants/ThemeSetting'

interface ThemeState {
  theme: TeamsThemeType
}

const initialState: ThemeState = {
  theme: 'default',
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<TeamsThemeType>) => {
      switch (action.payload) {
        case 'dark':
          document.body.classList.add('dark-theme')
          document.body.classList.remove('contrast')
          break
        case 'contrast':
          document.body.classList.add('contrast')
          document.body.classList.remove('dark-theme')
          break
        default:
          document.body.classList.remove('dark-theme')
          document.body.classList.remove('contrast')
          break
      }

      state.theme = action.payload
    },
  },
})

export const { setTheme } = themeSlice.actions

export const selectThemeSlice = (state: RootState) => state.themeSlice

export default themeSlice.reducer