import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configure'


interface IAuthCompanyState {
  id: string
}

const initialState: IAuthCompanyState = {
  id: '',
}

export const authCompanySlice = createSlice({
  name: 'authCompany',
  initialState,
  reducers: {
    setAuthCompany: (state, action: PayloadAction<{id: string}>) => {
      state.id = action.payload.id
    },
  },
})

export const { setAuthCompany } = authCompanySlice.actions

export const selectAuthCompanySlice = (state: RootState) => state.authCompanySlice

export default authCompanySlice.reducer