import React from 'react'
import DashboardTabComponent from './DashboardTabComponent'
import PageWrapper from '../../containers/App/PageWrapper'
import TopBar from '../../components/topbar'

const DashboardTabPage: React.FC = () => {
  return (
    <>
      <TopBar />
      <div className="dashboard main-content">
        <PageWrapper PageComponent={DashboardTabComponent} />
      </div>
    </>
  )
}

export default DashboardTabPage