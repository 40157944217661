import { Dispatch } from 'react'

interface IActions {
  type: string
  value: any
}

export const actions = (dispatch: Dispatch<IActions>) => {
  return {
    setUserId: (userId: string) => {
      dispatch({
        type: 'SET_USER_ID',
        value: userId,
      })
    },
  }
}