import React, { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import * as Sentry from '@sentry/react'
import { App } from 'antd'
import { useSubscription } from 'graphql-hooks'

import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { removeCorrelationId, selectNotificationsSlice } from '../../store/NotificationsSlice'
import { companyUpdateSubscription } from '../../graphql/custom-queries'

import { generateNotificationInfo } from '@vacationtracker/shared/components/notifications'
import { ISubscriptionEvent, ISubscriptionEventMessage } from '@vacationtracker/shared/types/notification'
import { setLeaveRequestActionEvent } from '../../store/leave-request-action-event-slice'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'


type CompanyEventResponse = {
  companyUpdates: ISubscriptionEvent
}

const Notifications = () => {
  const { notifications } = useAppSelector(selectNotificationsSlice)
  const { id: companyId } = useAppSelector(selectAuthCompanySlice)
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const notificationsRef = useRef(notifications)
  const { notification } = App.useApp()

  useSubscription<CompanyEventResponse>({
    query: companyUpdateSubscription,
    variables: { companyId },
  }, ({ data, errors }) => {
    if (errors) {
      Sentry.captureException(errors)
      return
    }

    let subscriptionEvent: ISubscriptionEventMessage | null = null
    let result
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      subscriptionEvent = JSON.parse(data?.companyUpdates?.originalEvent as string)
      result = JSON.parse(data?.companyUpdates?.result as string)
    } catch(err) {
      // Ignoring the error
    }

    if (!data || !data.companyUpdates) {
      return
    }

    if (
      data.companyUpdates?.code === 'error' ||
      (
        subscriptionEvent &&
        Array.isArray(notificationsRef.current) &&
        (
          subscriptionEvent.correlationId &&
          notificationsRef.current.includes(subscriptionEvent.correlationId)
        )
      )
    ) {
      const { action, options: { title,
        titleValues,
        message,
        description,
        descriptionValues,
        btn,
        duration,
        key,
        updateNotification,
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      } } = generateNotificationInfo(data.companyUpdates, formatMessage)
      if (!updateNotification) {
        return
      }
      const messageValue = title ?
        formatMessage({ id: title }, titleValues) :
        /^error\.[a-zA-Z0-9]+$/.test(message) ?
          formatMessage({ id: message }) :
          message

      // If this problem persists, please contact support and give them the following error code: ${ CORRELATION ID }.
      const errorMessage = action === 'error' ? formatMessage({ id: 'notifications.error' }, { correlationId: key }) : ''

      const descriptionValue = description && typeof description === 'string' && `${formatMessage({ id: description }, descriptionValues)} ${errorMessage}`
      notification[action]({
        key,
        message: messageValue,
        description: descriptionValue,
        btn,
        duration,
      })
      dispatch(removeCorrelationId(key))
    }
    switch (subscriptionEvent?.eventType) {
      case 'LEAVE_REQUEST_UPDATED':
      case 'LEAVE_REQUEST_CREATED':
      case 'LEAVE_REQUEST_CANCELLED':
      case 'LEAVE_REQUEST_APPROVED':
      case 'LEAVE_REQUEST_DENIED':
      case 'LEAVE_REQUEST_EXPIRED':
      case 'LEAVE_REQUEST_ADDED':
      case 'LEAVE_REQUEST_DELETED':
        if (result?.eventAction === 'LeaveRequestAction') {
          dispatch(setLeaveRequestActionEvent(subscriptionEvent))
        }
        break
      default:
        break
    }
  })

  useEffect(() => {
    notificationsRef.current = notifications
  }, [notifications])

  return (<></>)
}

export default Notifications
