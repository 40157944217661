interface IState {
  locale: {
    icon: string
    languageId: string
    locale: string
    name: string
  }
  setUserId: Function
}

export const initialSettings: IState = {
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'en',
  },
  // Placeholder functions
  // eslint-disable-next-line
  setUserId: () => {}
}

export const reducer = (state, action) => {
  const { type, value } = action
  switch (type) {
    case 'SET_USER_ID':
      localStorage.setItem('userId', value)
      return {
        ...state,
        userId: value,
      }
    default:
      return state
  }
}