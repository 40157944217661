import React from 'react'
import { Layout } from 'antd'
import ExternalIcon from '../../components/external-link-icon'
import LogoSvg from '../../assets/images/VT-Logo-Purple.svg'

const { Header } = Layout

const Topbar = () => {
  return (
    <Header>
      <span className="logo-wrapper">
        <LogoSvg />
      </span>
      <div className="right-menu">
        <a href="http://vacationtracker.io/" target="_blank" rel="noopener noreferrer">
          vacationtracker.io <ExternalIcon />
        </a>
      </div>
    </Header>
  )
}

export default Topbar
