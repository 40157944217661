import axios from 'axios'

import { ICheckUserId } from '@vacationtracker/shared/types/company'

const url = `${process.env.REACT_APP_API_URL}/microsoft`

export const getUserId = async (tenantId: string, token?: string, microsoftSassToken?: string): Promise<ICheckUserId> => {
  const response = await axios.post(`${url}/get-user`, {
    tenantId,
    token,
    microsoftSassToken,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.data
}

export const getMicrosoftToken = async (token: string, teamId: string) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/microsoft/token`, {
    teamId,
    token,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.data
}