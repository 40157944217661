import * as microsoftTeams from '@microsoft/teams-js'
import { TabInstance } from '@microsoft/teams-js'

export class MicrosoftTeams {
  msTeams: typeof microsoftTeams

  constructor() {
    this.msTeams = microsoftTeams
  }

  async initialize(): Promise<void> {
    await this.msTeams.app.initialize()
  }

  async getMicrosoftToken(): Promise<string> {
    await this.initialize()
    return this.msTeams.authentication.getAuthToken()
  }

  async getContext(): Promise<microsoftTeams.app.Context> {
    await this.initialize()
    return await this.msTeams.app.getContext()
  }

  async openInAppPurchaseFlow(): Promise<void> {
    await this.initialize()
    return this.msTeams.monetization.openPurchaseExperience()
  }

  async goTo(entityId: string): Promise<void> {
    await this.initialize()
    const tabsRes = (await this.msTeams.pages.tabs.getTabInstances())?.teamTabs as TabInstance & {appId: string; tabName: string}[]
    const appId = tabsRes[0]?.appId || process.env.REACT_APP_MICROSOFT_CLIENT_ID
    if (appId) {
      return this.msTeams.app.openLink(`https://teams.microsoft.com/l/entity/${appId}/${entityId}`)
    } else {
      throw new Error('No appId found')
    }
  }

  async setSettings(page: string) {
    await this.initialize()
    if (!process.env.REACT_APP_MICROSOFT_TABS_URL) {
      this.msTeams.pages.config.registerOnSaveHandler(saveEvent => {
        saveEvent.notifyFailure('Something went wrong. Please contact Vacation Tracker support and send them the following message "MICROSOFT_TABS_URL_MISSING".')
      })
    }
    switch (page) {
      // eslint-disable-next-line max-len
      // https://docs.microsoft.com/en-us/javascript/api/@microsoft/teams-js/microsoftteams.settings.settings?view=msteams-client-js-latest&preserve-view=true#@microsoft-teams-js-microsoftteams-settings-settings-suggesteddisplayname
      // suggestedDisplayName:
      // If the settings for an existing instance being updated, this call has no effect.
      case 'calendar':
        this.msTeams.pages.config.setValidityState(true)
        this.msTeams.pages.config.registerOnSaveHandler(saveEvent => {
          this.msTeams.pages.config.setConfig({
            entityId: 'calendar',
            suggestedDisplayName: 'Vacation Tracker Calendar',
            // websiteUrl: 'https://vacationtracker.io',
            contentUrl: `${process.env.REACT_APP_MICROSOFT_TABS_URL}/tabs/calendar`,
          })
          saveEvent.notifySuccess()
        })
        break
      default:
        this.msTeams.pages.config.setValidityState(true)
        this.msTeams.pages.config.registerOnSaveHandler(saveEvent => {
          this.msTeams.pages.config.setConfig({
            entityId: 'overview',
            suggestedDisplayName: 'Vacation Tracker Overview',
            // websiteUrl: `${process.env.REACT_APP_DASHBOARD_URL}/signin?platform=microsoft&redirect=/app/dashboard`,
            // websiteUrl: 'https://vacationtracker.io',
            contentUrl: `${process.env.REACT_APP_MICROSOFT_TABS_URL}/tabs/dashboard`,
          })
          saveEvent.notifySuccess()
        })
    }
  }
}
