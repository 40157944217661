import { availableLanguages } from '@vacationtracker/shared/i18n'
import { ILocale, LocaleEnum } from '@vacationtracker/shared/types/i18n'

export const getPrefferedLanguage = (userLanguage: LocaleEnum, teamsLanguage: string): ILocale => {
  const teamsLanguageSetting = teamsLanguage.substring(0, 2)
  if (teamsLanguageSetting !== userLanguage && availableLanguages[teamsLanguageSetting])  {
    return availableLanguages[teamsLanguageSetting]
  } else if (userLanguage && availableLanguages[userLanguage]) {
    return availableLanguages[userLanguage]
  } else {
    return availableLanguages.en
  }
}