import React, { Fragment, useEffect, useState } from 'react'
import { Select } from 'antd'
import { MicrosoftTeams } from '../../services/auth/microsoft/microsoft'
import { page, track } from '../../services/analytics/analytics'
import IntlMessages from '../../util/IntlMessages'
import ExternalIcon from '../../components/external-link-icon'

import { useAppDispatch } from '../../store/hooks'
import { setTheme } from '../../store/ThemeSlice'
import { setLocale } from '../../store/LocaleSlice'

import { availableLanguages } from '@vacationtracker/shared/i18n'

type TabType = 'dashboard' | 'calendar'
import { TeamsThemeType } from '@vacationtracker/shared/constants/ThemeSetting'


const { Option } = Select

const ConfigTabPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const [tabType, setTabType] = useState<TabType>('dashboard')
  const msAuth = new MicrosoftTeams()

  const configure = async () => {
    const msContext = await msAuth.getContext()
    const userLanguage = msContext.app.locale.toLowerCase().startsWith('fr') ? availableLanguages.fr : availableLanguages.en
    // TODO: check our user's language preferences
    dispatch(setLocale(userLanguage))
    msContext.app.theme && dispatch(setTheme(msContext.app.theme as TeamsThemeType))
    if (!msContext.page.id) {
      setTabType('dashboard')
    } else {
      msContext.page.id === 'overview'
        ? setTabType('dashboard')
        : setTabType('calendar')
    }
  }

  useEffect(() => {
    msAuth.setSettings(tabType)
  }, [tabType])

  useEffect(() => {
    configure()
    page()
    track('MICROSOFT_TAB_CONFIGURATION_VIEWED', { platform: 'microsoft' })
  }, [])

  return (
    <Fragment>
      <p style={{ margin: 10, marginTop: 30 }}>
        <IntlMessages id="app.configuration.welcome" />
      </p>
      <p style={{ margin: 10, marginTop: 30 }}>
        <IntlMessages id="app.configuration.options.info" />
        <Select defaultValue='dashboard' value={tabType} onChange={(value) => setTabType(value as TabType)}>
          <Option value='dashboard'><IntlMessages id="app.configuration.option.overview" /></Option>
          <Option value='calendar'><IntlMessages id="app.calendar" /></Option>
        </Select>
      </p>

      <p style={{ margin: 10 }}>
        <IntlMessages
          id="app.configuration.moreInfo"
          values={{
            link: (...chunks) => (
              <a href="https://vacationtracker.crisp.help/en/" target="_blank" rel="no noreferrer">
                {chunks} <ExternalIcon />
              </a>
            ),
          }}
        />
      </p>
      <p style={{ margin: 10 }}><IntlMessages id="app.happyVacationing" /></p>
    </Fragment>
  )
}

export default ConfigTabPage
