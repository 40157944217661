import React from 'react'
import { Button, Alert } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import { formatTimeString } from '@vacationtracker/shared/functions/get-days-or-hours'
import { convertHourFormats } from '@vacationtracker/shared/functions/convert-between-hour-formats'
import { getSafeLocale } from '@vacationtracker/shared/i18n'

import IntlMessages from '../../util/IntlMessages'

import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'

interface IUserTodayOff {
  todayOff: any
  handleCancelLeave?: Function
  locale: LocaleEnum
  userHourFormat: HourFormatEnum
}



const UserTodayOff: React.SFC<IUserTodayOff> = ({ todayOff, handleCancelLeave, locale, userHourFormat }) => {
  const safeLocale = getSafeLocale(locale)
  let convertedEndHour
  let convertedStartHour
  if (todayOff.isPartDay) {
    const partDay = todayOff.partDay
    const { value: startHourValue, minute: startMinute, amOrPm: amOrPmStart } = convertHourFormats(
      userHourFormat,
      partDay ? partDay?.startHour as number : todayOff.partDayStartHour as number,
      partDay ? partDay?.startMinute as number : 0
    )
    const { value: endHourValue, minute: endMinute, amOrPm: amOrPmEnd } = convertHourFormats(
      userHourFormat,
      partDay ? partDay?.endHour as number : todayOff.partDayEndHour as number,
      partDay ? partDay?.endMinute as number : 0
    )
    convertedStartHour = formatTimeString({hour: startHourValue, minute: startMinute, showZeroPadding: false, hourFormat: userHourFormat, amOrPm: amOrPmStart})
    convertedEndHour = formatTimeString({hour: endHourValue, minute: endMinute, showZeroPadding: false, hourFormat: userHourFormat, amOrPm: amOrPmEnd})
  }
  return (
    <Alert type="success"
      icon={<InfoCircleOutlined />}
      showIcon
      style={{ marginBottom: 20 }}
      message={<IntlMessages id="components.userTodayOff.leaveInfo" />}
      description={
        <>
          <div className="user-offtoday">
            {dayjs(todayOff.startDate as string).locale(safeLocale).format('YYYY-MM-DD') === dayjs(todayOff.endDate as string).locale(safeLocale).format('YYYY-MM-DD') ?
              <>
                {
                  todayOff.isPartDay ?
                    <IntlMessages id="components.userTodayOff.offTodayPartDay" values={{
                      leaveTypeName: todayOff.leaveType.name,
                      from: convertedStartHour,
                      to: convertedEndHour,
                    }} /> :
                    <IntlMessages id="components.userTodayOff.offTodayFullDay" values={{
                      leaveTypeName: todayOff.leaveType.name,
                      today: dayjs(todayOff.startDate as string).locale(safeLocale).format('MMMM D, YYYY'),
                    }} />
                }
              </> :
              <IntlMessages id="components.userTodayOff.offFromTo" values={{
                leaveTypeName: todayOff.leaveType.name,
                startDate: dayjs(todayOff.startDate as string).locale(safeLocale).format('MMMM D, YYYY'),
                endDate: dayjs(todayOff.endDate as string).locale(safeLocale).format('MMMM D, YYYY'),
              }} />
            }
            {handleCancelLeave &&
              <Button onClick={() => handleCancelLeave(todayOff, 'today')} danger
                style={{ float: 'right', position: 'relative', top: -15 }}>
                <IntlMessages id="app.cancelLeave" />
              </Button>
            }
          </div>
          {todayOff.reason &&
            <p className="user-offtoday-reason">
              <IntlMessages id="components.userTodayOff.offTodayReason" values={{ reason: todayOff.reason }} />
            </p>
          }
        </>
      }
    />
  )
}

export default UserTodayOff
