import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configure'

interface NotificationsState {
  notifications: string[]
}

const initialState: NotificationsState = {
  notifications: [],
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addCorrelationId: (state, action: PayloadAction<string>) => {
      state.notifications.push(action.payload)
    },
    removeCorrelationId: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(notification => notification !== action.payload)
    },
  },
})

export const { addCorrelationId, removeCorrelationId } = notificationsSlice.actions

export const selectNotificationsSlice = (state: RootState) => state.notificationsSlice

export default notificationsSlice.reducer